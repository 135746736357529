import React, { FC } from 'react'
import FilterPill from 'components/filter/FIlterPill'
import FilterUtils from 'utils/filter'
import FilterOptionSalary from 'components/filter/Filter/FilterOptionSalary'
import useTranslation from 'translations/hooks/useTranslation'
import { FilterQueryData, IFilterData } from 'types/filter'
import { GrammarCaseKey } from 'translations/types'
import StringUtils from 'utils/string'

export type OptionBase = {
  _id: string
  name: string
  slug: string
  shortName?: string
  category?: {
    _id: string
    name: string
    slug: string
  }
  isBoolean?: boolean
}

type IProps = {
  optionsKey: keyof IFilterData
  options: OptionBase[]
  activeOptions: FilterQueryData
  eventHandlers: {
    onSelectPill?: (value: string) => void
    onSalaryChange?: (value: string, currency: string) => void
    onTogglePill?: (key: string, value: string) => void
    onSaveAndCloseClick: () => void
  }
  salaryValue?: string
  currencyValue?: string
  salarySelectOptions?: {
    step?: number
    unit?: 'month' | 'hour'
  }
}

const FilterOptions: FC<IProps> = ({
  optionsKey,
  options,
  eventHandlers,
  activeOptions,
  salaryValue = '0',
  currencyValue = 'EUR',
  salarySelectOptions,
}) => {
  const { onSelectPill, onSalaryChange, onTogglePill, onSaveAndCloseClick } =
    eventHandlers
  const { t } = useTranslation()
  let content

  if (optionsKey === 'salary') {
    content = (
      <div className="container filter-padding-top">
        <FilterOptionSalary
          options={salarySelectOptions}
          defaultValue={salaryValue}
          onUpdate={onSalaryChange}
          defaultCurrencyValue={currencyValue}
        />
      </div>
    )
  } else if (!!options[0].category) {
    content = (
      <div className="filter filter--visible">
        {FilterUtils.groupCategoryData(options).map((optionGroup) => (
          <div key={optionGroup.category.slug} className="container filter-padding-top">
            <p className="paragraph paragraph--small paragraph--bold capitalize margin-bottom-tiny">
              {t(`freelancers.categoryName[${optionGroup.category.slug}]`, {
                grammarCase: GrammarCaseKey.Other,
              })}
            </p>
            <div className="fiter__positions-wrapper">
              {optionGroup.tags.map((tag) => (
                <FilterPill
                  key={tag.slug}
                  onClick={() => onSelectPill(tag.slug)}
                  isActive={activeOptions[optionsKey]?.includes(tag.slug)}
                  filterName={tag.name}
                  filterShortName={tag.shortName}
                  slug={tag.slug}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  } else {
    content = (
      <div className="container filter-padding-top">
        <div className="fiter__positions-wrapper">
          {options.map((option) => (
            <FilterPill
              key={option.slug}
              onClick={
                option.isBoolean
                  ? () => onTogglePill(option.slug, 'true')
                  : () => onSelectPill(option.slug)
              }
              isActive={
                option.isBoolean
                  ? activeOptions[option.slug].includes('true')
                  : activeOptions[optionsKey]?.includes(option.slug)
              }
            >
              {optionsKey === 'jobCategories'
                ? StringUtils.capitalize(
                    t(`freelancers.categoryName[${option.slug}]`, {
                      grammarCase: GrammarCaseKey.Other,
                    })
                  ) || t(`common.companyCategoryName[${option.slug}]`)
                : option.name}
            </FilterPill>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="filter filter--visible">
      {content}
      <div className="container filter-padding-top center">
        <hr />
        <span
          onClick={onSaveAndCloseClick}
          className="button-secondary button-secondary--black margin-top-20"
        >
          {t('common.actions.saveAndClose')}
        </span>
      </div>
    </div>
  )
}

export default FilterOptions
